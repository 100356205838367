.uxf-text-link {
    @apply transition;

    position: relative;

    &--variant-default {
        :root .light & {
            color: theme("colors.primary.DEFAULT");
            font-weight: theme("fontWeight.medium");
            text-decoration-line: underline;

            &:hover,
            &:active {
                border-color: theme("colors.primary.400");
                content: "";
            }
        }
    }

    &--variant-text {
        :root .light & {
            color: inherit;

            &:hover,
            &:active {
                color: inherit;
                opacity: 0.8;
            }
        }
    }

    &--variant-white {
        :root .light & {
            @apply is-hoverable:text-white;

            color: theme("colors.white");

            &:hover,
            &:active {
                color: theme("colors.white");
                opacity: 0.8;
            }
        }
    }
}
